import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { FormEvent, useRef, useState } from "react";
import useRequestReport from "../../hooks/useRequestReport";
import useSendMagicLink from "../../hooks/useSendMagicLink";
import { ReportFormT } from "../../utils/types";
import { ReportFormFields } from "../../utils/enums";
// import { SnackbarContext } from "../../context/SnackbarContext";

interface EmailDialogBoxProps {
  // Whether the dialog is open
  open: boolean;
  // Toggles the dialog open and closed
  toggleOpen: () => void;
  // The selected social platform
  selectedPlatform: string | null;
  // ReactFormApi object containing form management methods and properties
  form?: ReportFormT;
  // isLoginOnly: Don't request a new report, just send a magic link
  isLoginOnly?: boolean;
  // isLast100: Whether the report is capped at 100 posts
  isLast100?: boolean;
}

enum EmailDialogError {
  EMAIL_REGEX = "emailRegex",
  EMAIL_MUTATION = "emailMutation",
  REPORT_MUTATION = "reportMutation",
}

const errorMap: Record<EmailDialogError, string> = {
  [EmailDialogError.EMAIL_REGEX]: "Please enter a valid email address.",
  [EmailDialogError.EMAIL_MUTATION]:
    "Error sending email. Please verify address or try again later.",
  [EmailDialogError.REPORT_MUTATION]:
    "Error sending report. Please try again later.",
};

/**
 * @returns The EmailDialogBox where users enter their email to receive the report
 */
export default function EmailDialogBox(props: EmailDialogBoxProps) {
  const { open, toggleOpen, selectedPlatform, form, isLoginOnly, isLast100 } = props;
  const { getFieldValue } = form || {};
  const [error, setError] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  // const { showSnackbar } = useContext(SnackbarContext);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const { mutate: sendMagicLinkMutate, isPending: sendMagicLinkIsPending } =
    useSendMagicLink();
  const { mutate: requestReportMutate, isPending: requestReportIsPending } =
    useRequestReport();

  const isPending: boolean =
    sendMagicLinkIsPending || (!isLoginOnly && requestReportIsPending);

  /**
   * Updates component state to display the error below the email input
   *
   * @param error the POST error from the axios mutation
   */
  const handleError = (error: Error, errorType: EmailDialogError): void => {
    console.log(error);
    const message: string = errorMap[errorType];
    setError(message);
  };

  /**
   * Invokes the request report mutation and shows the success dialog if successful. Otherwise, displays errors
   *
   * @param email the email string from the text input
   */
  const handleSendMagicLinkMutateSuccess = (email: string): void => {
    if (!isLoginOnly) {
      // showSnackbar("Email sent", "success");
      requestReportMutate(
        {
          platform: selectedPlatform || "",
          handle: getFieldValue?.("handle") || "",
          date: isLast100 ? "100p" : form?.getFieldValue(ReportFormFields.DATE_RANGE) || "100p",
          template: getFieldValue?.("template") || "",
          email: email,
          domain: window.location.origin,
          job_status: "created",
        },
        {
          onSuccess: () => {
            setShowSuccessDialog(true);
          },
          onError: (error: Error) =>
            handleError(error, EmailDialogError.REPORT_MUTATION),
        }
      );
    } else {
      setShowSuccessDialog(true);
    }
  };

  /**
   * Submits the form if the user enters enter or the space bar, otherwise, clears any errors
   *
   * @param event the keydown event
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      formRef.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    } else if (error) {
      setError(null);
    }
  };

  /**
   * Invokes the send magic link mutation and then the request report mutation if successful. Otherwise, displays errors
   *
   * @param event The submit event
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData: FormData = new FormData(event.currentTarget);
    const email: string | null = formData.get("email") as string;
    // This regex validates email addresses:
    // - Allows alphanumeric characters, dots, underscores, percent signs, plus signs, and hyphens before the @ symbol
    // - Requires a single @ symbol
    // - Allows alphanumeric characters and hyphens in the domain name
    // - Requires a dot after the domain name
    // - Requires at least two characters for the top-level domain (e.g., .com, .org)
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      handleError(
        new Error("Invalid email format"),
        EmailDialogError.EMAIL_REGEX
      );
      return;
    }

    sendMagicLinkMutate(email, {
      onSuccess: () => {
        handleSendMagicLinkMutateSuccess(email);
      },
      onError: (error: Error) =>
        handleError(error, EmailDialogError.EMAIL_MUTATION),
    });
  };

  const initialDisplay = (
    <>
      <DialogTitle>What's your email?</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          error={!!error}
          helperText={error}
          onKeyDown={handleKeyDown}
        />
        <DialogContentText>
         {!isLoginOnly && <>We will email you the report when it's ready. </> }
         We don't share or sell your email address.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen}>Cancel</Button>
        <LoadingButton
          loading={isPending}
          type="submit"
          variant="contained"
          disabled={isPending}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </>
  );

  const successDisplay = (
    <>
      <DialogTitle>Verify Your Email</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <DialogContentText>
          Please check your email for a verification link to continue. Check your spam folder!
        </DialogContentText>
      </DialogContent>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      // Disable Dialog's default autofocus
      disableRestoreFocus
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
        ref: formRef,
      }}
    >
      {showSuccessDialog ? successDisplay : initialDisplay}
    </Dialog>
  );
}
