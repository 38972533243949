// User Class to hold user data
export class UserClass {
  email: string;
  // subscription: "free" | "personal" | "pro" | "elite";
  credits_active: number;
  credits_reserved: number;
  credits_used: number;

  constructor(authUser: string, userData: any) {
    const { user } = userData;
    // Object.assign(this, authUser);
    this.email = authUser || "";
    // this.subscription = user.subscription || "free";
    this.credits_active = user.credits_active || 0;
    this.credits_reserved = user.credits_reserved || 0;
    this.credits_used = user.credits_used || 0;

  }
}
