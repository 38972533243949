// Global
import { ReactNode } from "react";
import { useMatch } from "@tanstack/react-router";

// MUI
import { Box } from "@mui/material";
// import theme from "./theme";

// Local

interface Props {
  children?: ReactNode;
  subNavHeight?: number;
}

const MainStageContainer = (props: Props) => {
  const isReportPage = useMatch({ from: "/r/$handle/$random", shouldThrow: false });

  return (
    <Box
      id="base-container"
      sx={{
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box
        id="base-container-content"
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: isReportPage ? "900px" : "600px",
          // maxWidth:"900px",
          px: { xs: 1, md: 1 },
          justifyContent: "center",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default MainStageContainer;
