import { createTheme, responsiveFontSizes } from "@mui/material";

// Typography constants
export const avatarSize = "2rem";
export const verySmallFontSize = "0.8rem";
export const smallFontSize = "0.95rem";
export const defaultFontSize = "1rem";
export const largeFontSize = "1.1rem";

// Color palette
export const COLORS = {
  backgroundGray: "#FAFAFA",
  primaryBlue: "#190051",
  accentOrange: "#D8783E",
  hoverBlue: "#1565C0",
  darkGray: "#333",
  muiTextSecondary: "#333333",
  secondaryGray: "#8F9BB3",
  borderGray: "rgba(0, 0, 0, 0.12)",
  nearBlack: "#08080A",
  white: "#fff",
  black: "#000",
  darkRed: "#B22222",
  darkGreen: "#006400",
  success: "#4CAF50",
};

// AMP Custom Theme
let theme = createTheme({
  palette: {
    background: {
      default: COLORS.backgroundGray,
      paper: COLORS.white,
    },
    primary: {
      main: COLORS.primaryBlue,
    },
    secondary: {
      main: COLORS.accentOrange,
    },
    success: {
      main: COLORS.primaryBlue,
    },
    info: {
      main: COLORS.secondaryGray,
    },
    warning: {
      main: COLORS.accentOrange,
    },
    error: {
      main: COLORS.darkRed,
    },
    text: {
      // primary: inherit,
      // secondary: COLORS.secondaryGray,
    },
  },

  // Note: The only acceptable font weights are 400, 600, and 700
  typography: {
    fontFamily: ["Barlow Semi Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2rem",
      // fontWeight: 600,
      color: COLORS.primaryBlue,
    },
    h2: {
      fontSize: "1.6rem",
      // fontWeight: 600,
      color: COLORS.primaryBlue,
    },
    // h3: {
    //   fontSize: "1.2rem",
    //   fontWeight: 500,
    //   color: COLORS.nearBlack,
    // },
    //   // h4: Gray subtitle
    h4: {
      fontSize: "1.3rem",
      // fontWeight: 600,
      color: COLORS.primaryBlue,
    },
    h5: {
      fontSize: "1.25rem",
      color: COLORS.primaryBlue,
    },
    h6: {
      fontSize: "1.2rem",
      color: COLORS.primaryBlue,
    },
    // subtitle1: {
    //   fontSize: "1rem",
    //   fontWeight: 600,
    // color: COLORS.airaPink,
    // },
    //   subtitle2: {
    //     fontSize: "1.15rem",
    //     fontWeight: 600,
    //     color: COLORS.nearBlack,
    //   },
    body1: {
      fontSize: smallFontSize,
      fontFamily: "Roboto",
    },
    button: {
      fontFamily: "Roboto",
    },

    // body2: {
    //   fontFamily: "Helvetica",
    //   fontSize: verySmallFontSize,
    //   color: COLORS.darkGray,
    // },
    //   caption: {
    //     fontFamily: "Courier New, monospace",
    //     fontSize: smallFontSize,
    //   },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: COLORS.backgroundGray,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&:hover": {
            color: COLORS.hoverBlue,
          },
        },
      },
      variants: [
        {
          // New Variant: "isNotButton" (no clicking)
          props: { className: "isNotButton" },
          style: {
            cursor: "auto",
            "&:hover": {
              color: "inherit",
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        underlineNone: {
          color: COLORS.secondaryGray,
          cursor: "pointer",
          "&:hover": {
            color: COLORS.hoverBlue,
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
