export enum Platform {
  X = "x",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
}

export enum ReportFormFields {
  HANDLE = "handle",
  POST_COUNT = "postCount",
  DATE_RANGE = "dateRange",
  TEMPLATE = "template",
  MODE = "mode",
}

export enum HandleValidationErrors {
  INVALID_URL_OR_HANDLE = "Invalid URL or handle",
  UNKNOWN_HANDLE = "",
  EMPTY_HANDLE = "A handle or valid URL is required",
}
