// Global
import * as React from "react";
import { signOut } from "aws-amplify/auth";
import { useNavigate, useRouterState } from "@tanstack/react-router";

// MUI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useMediaQuery, useTheme } from "@mui/material";

// Local
import Logo from "./Logo";
import { useUser } from "../hooks/useUser";
import EmailDialogBox from "../components/EmailDialogBox/EmailDialogBox";

function ResponsiveAppBar() {
  // Hooks
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { user, logout } = useUser();
  const navigate = useNavigate();

  // Get current route
  const currentRoute = useRouterState({ select: (s) => s.location.pathname });

  // State
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  // Dynamic pages array
  const pages = React.useMemo(() => {
    const basePages = ["Home", "Reports", "Account", "FAQ"];
    if (!isMobile) {
      return basePages;
    } else {
      if (user) {
        return ["Home", "Reports", "Account", "FAQ", "Logout"];
      } else {
        return ["Home", "Login", "Reports", "Account", "FAQ"];
      }
    }
  }, [isMobile, user]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Updated handleMenuItemClick
  const handleMenuItemClick = async (setting: string) => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    // User Logout
    if (setting === "Logout") {
      try {
        await signOut();
        logout();
        navigate({ to: "/login", search: { loggedout: "true" } });
      } catch (error) {
        console.error("Error signing out:", error);
      }
    }
    // User Login
    else if (setting === "Login") {
      setLoginOpen(true);
    }

    // Navigate to Home
    else if (setting === "Home") {
      navigate({ to: "/" });
    }

    // Navigate to Account
    else if (setting === "Account") {
      navigate({ to: "/account" });
    }

    // Navigate to FAQ
    else if (setting === "FAQ") {
      navigate({ to: "/faq" });
    }

    // Navigate to Reports
    else if (setting === "Reports") {
      navigate({ to: "/r" });
    }
  };

  const routeMap = {
    Home: "/",
    Reports: "/r",
    Account: "/account",
    FAQ: "/faq",
  } as const;

  const isCurrentRoute = (page: string): boolean => {
    return routeMap[page as keyof typeof routeMap] === currentRoute;
  };

  const loginSetting = user ? "Logout" : "Login";

  return (
    <>
      <AppBar
        id="app-bar"
        elevation={0} // Controls elevation and shadow
        sx={{
          // position: {xs: "sticky", md: "absolute"},
          position: "sticky",
          display: "flex",
          alignItems: "center",
          width: "100vw",
          top: 0,
          backgroundColor: "background.default",
          flexGrow: 0,
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "md", mx: 0 }}>
          <Toolbar>
            <Logo />

            {/* Mobile Menu: Dropdown */}
            <Box
              id="mobile-drop-down"
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "end",
                mr: 0,
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="avatar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ pr: 0 }}
              >
                <MenuIcon color="primary" fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {pages.map((page) => (
                  // Avatar Menu Items
                  <MenuItem key={page} onClick={() => handleMenuItemClick(page)}>
                    <Typography sx={{ textAlign: "center" }}>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Desktop Menu: Horizontal */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {pages.map((page) => (
             <Button
             key={page}
             onClick={() => handleMenuItemClick(page)}
             disabled={isCurrentRoute(page)}
             sx={{ 
               my: 2, 
               color: "primary", 
               display: "block",
               // Override disabled styles to maintain normal appearance
               "&.Mui-disabled": {
                 color: "primary.main"
               }
             }}
           >
             <Typography
               variant="button"
               sx={{  fontWeight: isCurrentRoute(page) ? 600 : "normal" }}
             >
               {page}
             </Typography>
           </Button>
              ))}
            </Box>

            {!isMobile && (
              <>
                {/* User (Avatar) Dropdown Menu (Desktop) */}
                <Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      {user ? (
                        <AccountCircleIcon color="primary" fontSize="large" />
                      ) : (
                        <AccountCircleOutlinedIcon color="primary" fontSize="large" />
                      )}
                    </IconButton>
                  </Tooltip>

                  {/* User Menu */}
                  <Menu
                    sx={{ mt: "45px" }}
                    id="user-menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {loginSetting === "Logout" && (
                      <MenuItem key="My Account" onClick={() => handleMenuItemClick("Account")}>
                        <Typography sx={{ textAlign: "center" }}>My Account</Typography>
                      </MenuItem>
                    )}
                    <MenuItem key={loginSetting} onClick={() => handleMenuItemClick(loginSetting)}>
                      <Typography sx={{ textAlign: "center" }}>{loginSetting}</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Toolbar>
        </Box>
      </AppBar>
      <EmailDialogBox
        open={loginOpen}
        toggleOpen={() => setLoginOpen(false)}
        isLoginOnly={true}
        selectedPlatform={null}
      />
    </>
  );
}
export default ResponsiveAppBar;
