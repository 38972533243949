// Query keys
export const userQueryKey = "userQueryKey";
export const reportsListQueryKey = "reportsListQueryKey";
export const reportQueryKey = "reportQueryKey";
export const getAnswerQueryKey = "getAnswerQueryKey";

// Mutation keys
export const startReportMutationKey = "startReportMutationKey";
export const requestReportMutationKey = "requestReportMutationKey";
export const askQuestionMutationKey = "askQuestionMutationKey";
