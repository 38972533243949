import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import '@fontsource/barlow-semi-condensed';
import "@fontsource/roboto";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./layout/theme";

import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import { UserContextProvider } from "./auth/UserContext";
import { SnackbarProvider } from "./context/SnackbarProvider";
import { ModalProvider } from "./context/ModalProvider";
import { routeTree } from "./routeTree.gen";
import ErrorWrapper from "./navigation/ErrorWrapper";
import './styles/animations.css';

// Tanstack React Router
// Import the generated route tree
const router = createRouter({ routeTree });
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Tanstack React Query Client
const queryClient = new QueryClient();

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ErrorWrapper>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme>
            <QueryClientProvider client={queryClient}>
              <UserContextProvider>
                <SnackbarProvider>
                  <ModalProvider>
                    <RouterProvider router={router} />
                  </ModalProvider>
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </SnackbarProvider>
              </UserContextProvider>
            </QueryClientProvider>
          </CssBaseline>
        </ThemeProvider>
      </ErrorWrapper>
    </StrictMode>
  );
}
